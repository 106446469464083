import { getImage, StaticImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/insurance-software-development/Anchors'
import InsuranceBenefits from '~/mock/services/insurance-software-development/InsuranceBenefits'
import InsuranceBusinesses from '~/mock/services/insurance-software-development/InsuranceBusinesses'
import InsuranceExpertise from '~/mock/services/insurance-software-development/InsuranceExpertise'
import InsuranceFAQ from '~/mock/services/insurance-software-development/InsuranceFAQ'
import InsuranceProcess from '~/mock/services/insurance-software-development/InsuranceProcess'
import InsuranceRequirements from '~/mock/services/insurance-software-development/InsuranceRequirements'
import InsuranceServices from '~/mock/services/insurance-software-development/InsuranceServices'
import InsuranceWhyHire from '~/mock/services/insurance-software-development/InsuranceWhyHire'
import { fileToImageLikeData } from '~/utils'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import useInsuranceSoftwareDevelopmentStaticQuery from '~/views/Services/InsuranceSoftwareDevelopment/useInsuranceSoftwareDevelopmentStaticQuery'

import * as containerStyles from './InsuranceSoftwareDevelopment.module.scss'

const clientName = 'Serhii'

const InsuranceSoftwareDevelopment = () => {
  const query = useInsuranceSoftwareDevelopmentStaticQuery()
  const portfolioBabel = getImage(fileToImageLikeData(query.portfolioBabel))
  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const clientRef = getImage(fileToImageLikeData(query.clientRef))

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Insurance Software"
        title="Development Services"
        description="We develop insurance solutions that protect your business growth with convenience, accuracy, compliance, and safety."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Insurance Software Development Services"
        breadcrumbCurrentPath="/services/insurance-software-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.insuranceDevServicesIconSection}
      >
        <HeadSection
          position="center"
          title="Insurance software development services we offer"
          description="We provide versatile services covering different insurance cases. Whether you need software for life insurance, business income coverage, or other types of insurance, our team will be your reliable ally on your project path."
          descriptionLarge
        />
        <Service3DIconCard dataContent={InsuranceServices} />
      </section>

      <section
        id="businesses"
        className={containerStyles.insuranceBusinessesSection}
      >
        <HeadSection
          position="center"
          title="We guarantee the growth for businesses of any size"
          description="Codica is committed to elevating your presence in the insurance sector as a startup, a small to medium enterprise, or a well-established corporation. Our expertise sets us apart from other insurance software development companies. By comprehensively grasping your project requirements, we are dedicated to moving your enterprise toward prosperity."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={InsuranceBusinesses} />
      </section>

      <CallToAction
        title="Seeking a partner to build an insurance solution?"
        subtitle="Our dedicated experts will help you."
        classNameForGA="servicesCTA_1"
        linkName="Let’s discuss"
        link="/contacts/"
      />

      <section className={containerStyles.insuranceProcessSection} id="process">
        <HeadSection
          position="center"
          title="Custom insurance software development process"
          description="With over eight years of experience, we have refined the development process to deliver the best results. We use agile practices and regular progress checks to meet the project requirements and budget."
          descriptionLarge
        />
        <ServicesProcess dataContent={InsuranceProcess} />
        <SubtractBlock isTop />
      </section>

      <section
        id="benefits"
        className={containerStyles.insuranceBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Benefits of our insurance software development services"
          description="We provide you with solutions that move your business to success. With the unparalleled flexibility, scalability, and compliance of our software, you achieve maximum productivity and customer satisfaction."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={InsuranceBenefits} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.insuranceTechStackSection}
      >
        <HeadSection
          position="center"
          title="Best insurance software development tech stack to build custom solutions"
          description="When providing financial & insurance software development services, we choose a proven tech stack. It helps us optimize the delivery on time and per requirements. Reliable technologies are tools to create insurance solutions that meet the industry’s complex demands and customer expectations."
          titleLarge
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section className={containerStyles.insuranceCaseStudySection}>
        <HeadSection position="center" title="Featured case study" />
        <DiscoverWorksItem
          gradient="gradientBabel"
          image={portfolioBabel}
          header="Insurance progressive web app for Babel Cover"
          title="This app is part of Babel Cover’s insurance eco-system, aimed at sustainability, transparency, and rewards linking. The solution lets you purchase insurance policies, report incidents on the spot, and get cashback for an incident-free time."
          resultsLeft="Ultra-secure data storage and payment processing"
          resultsRight="Robust, accurate, and easy-to-use solution"
          link="insurance-progressive-web-application"
          review="“Babel Cover is a revolutionary product in the insurance domain, and I’m happy we had a chance to contribute to its success. PWA technology simplified development, speeded up the launch, and reduced costs. The result is a scalable, reliable, and secure app.”"
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/Sergey.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Ruby on Rails developer at Codica"
        />
      </section>

      <section
        id="requirements"
        className={containerStyles.insuranceRequirementsSection}
      >
        <HeadSection
          position="center"
          title="Requirements for advanced insurance software"
          description="Digital solutions must meet the complexities and demands of the insurance industry. Such software should foster efficiency, compliance, innovation, and growth. The following are the properties of insurance software that deliver the best technical and usability results."
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={InsuranceRequirements} />
      </section>

      <CallToAction
        title="Thinking of an advanced insurance solution?"
        subtitle="We will help you build it."
        classNameForGA="servicesCTA_2"
        linkName="Reach out to us"
        link="/contacts/"
      />

      <section
        id="cases"
        className={containerStyles.insuranceSuccessStoriesSection}
      >
        <HeadSection
          position="center"
          title="Cases"
          description="We are delighted to help you with your project. Let our successful solutions showcase our expertise and commitment that brought our clients’ businesses to new levels and exceeded their expectations."
          descriptionLarge
        />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.insuranceWhyHireSection}>
        <HeadSection
          position="center"
          title="Why hire Codica insurance software development company"
          description="We provide tailored solutions, offering strategic advantages to your business. Our end-to-end development and continuous support will provide you with critical modernization and competitive potential."
          descriptionLarge
        />
        <CompanyWhyHireBlock dataContent={InsuranceWhyHire} />
      </section>

      <section className={containerStyles.insuranceDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Our credible experience in software development"
          description="With a proven track record, Codica is your reliable partner on your path to success."
          descriptionLarge
        />
        <ServicesDevExpertiseCompany dataContent={InsuranceExpertise} />
      </section>

      <section className={containerStyles.insuranceSkilledSection}>
        <HeadSection
          position="center"
          title="We are proud of having a skilled and reliable development team"
          description="With years of experience and dozens of successful projects, we know how to help you build an intuitive and robust insurance solution."
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'When you choose Codica, you can count on transparent communication, optimized development processes, and sticking to the timeline. We approach each project carefully to meet your specific needs and requirements within the planned budget.',
            },
            {
              text: 'The insurance industry has high compliance and security standards, a crucial aspect to consider. Our team follows the latest practices to build solutions that ensure sensitive data protection and the accuracy of payments. Partnering with us, be sure we will create the best architecture to implement your insurance solution, regardless of complexity.',
            },
          ]}
        />
      </section>

      <section className={containerStyles.insuranceOurAchievementsSection}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="We are happy to share our accomplishments with you. The leading rating and review platforms have acknowledged our work for several years."
          descriptionLarge
        />
        <AwardsServicesSection />
      </section>

      <section className={containerStyles.insuranceClientQuotesSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="Genuine feedback from our clients is precious to us and inspires further progress. We invite you to explore the transformative business experiences of people who entrusted their projects to Codica."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Leon Zinger"
            location="Israel"
            position="Founder at RefDental"
            image={clientRef}
            quote={[
              {
                text: '“What we like most about working with Codica is their deep understanding of our business needs. They quickly grasped our initial idea and delivered our Minimum Viable Product within the specified time and budget. Thanks to this, we could get user feedback fast”',
              },
            ]}
            link="dental-imaging-management-system"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s create robust insurance software for your business!"
          widthTitle="700px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our software developers will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={InsuranceFAQ} />
      </section>
    </MainLayout>
  )
}

export default InsuranceSoftwareDevelopment
