import { ExpertiseSectionMock } from '~/types/mock'

const InsuranceRequirements: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_91',
    title: 'Ease to scale and adjust',
    description:
      'Insurance software must scale to accommodate changes in the user base and transactions. Also, it should be flexible to adjust to business needs, regulations, and market trends.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Compliance and security',
    description:
      'Insurance solutions should be built with relevant features and data encryption. Maintaining data integrity and confidentiality is a must-have for insurance software.',
  },
  {
    icon: 'spriteIcon_3',
    title: 'Integration capabilities',
    description:
      'Insurance industry apps should be easily integrated with additional systems and third-party tools. APIs and middleware are also crucial for data exchange and interoperability.',
  },
  {
    icon: 'spriteIcon_48',
    title: 'Advanced analytics and reporting',
    description:
      'To provide meaningful insights, insurance solutions should encompass advanced data analytics tools. Generating comprehensive reports is necessary for informed decision-making.',
  },
  {
    icon: 'spriteIcon_11',
    title: 'Customer-centric features',
    description:
      'Enhancing insurance solutions is possible with self-service portals for policy management and claim submission. Such software can be personalized with offers and services.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Capabilities in artificial intelligence and machine learning',
    description:
      'AI and ML features make insurance solutions more user-friendly, versatile, and efficient for customer support, predictive underwriting, fraud detection, and claims assessment.',
  },
]

export default InsuranceRequirements
