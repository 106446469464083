// extracted by mini-css-extract-plugin
export var insuranceBenefitsSection = "J_gC";
export var insuranceBusinessesSection = "J_gz";
export var insuranceCaseStudySection = "J_gF";
export var insuranceClientQuotesSection = "J_gN";
export var insuranceDevExpertiseSection = "J_gK";
export var insuranceDevServicesIconSection = "J_gy";
export var insuranceOurAchievementsSection = "J_gM";
export var insuranceProcessSection = "J_gB";
export var insuranceRequirementsSection = "J_gG";
export var insuranceSkilledSection = "J_gL";
export var insuranceSuccessStoriesSection = "J_gH";
export var insuranceTechStackSection = "J_gD";
export var insuranceWhyHireSection = "J_gJ";