import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useAutomotiveSoftwareDevelopmentStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      portfolioBabel: file(
        relativePath: { eq: "homepage/discover-works-portfolio-14-desktop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      clientRef: file(
        relativePath: { eq: "avatars/clients/large-photo/client-ref.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredOne: file(
        relativePath: { eq: "company-photo/company-photo-34.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredTwo: file(
        relativePath: { eq: "company-photo/company-photo-33.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useAutomotiveSoftwareDevelopmentStaticQuery
