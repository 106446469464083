import { ExpertiseSectionMock } from '~/types/mock'

const InsuranceBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'For startups',
    description:
      'We will ensure you get the maximum benefits if you start your business journey. With our cost-effective and scalable solutions, you can optimize your business operations and improve customer engagement.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'For small & medium-sized businesses',
    description:
      'As an insurance software development company, we cater to your needs to help you thrive. Providing top-notch solutions and updates, our team ensures that you get adaptable systems for market expansion and business productivity.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'For large enterprises',
    description:
      'Our insurance software safeguards your business flow and audience engagement. We ensure regulatory compliance, stringent security measures, and a personalized customer experience at any scale.',
  },
]

export default InsuranceBusinesses
