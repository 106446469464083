import { ExpertiseSectionMock } from '~/types/mock'

const InsuranceBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_3',
    title: 'Improved claims processing',
    titleWidth: 'maxWidth_300',
    description:
      'With insurance solutions, including software development insurance, you can easily handle claim processing, from submission to settlement.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Efficiency with automation',
    titleWidth: 'maxWidth_300',
    description:
      'Insurance software streamlines insurance operations and reduces errors. Insurers can deal with sophisticated tasks and bring customer service to a new level.',
  },
  {
    icon: 'spriteIcon_30',
    title: 'Elaborate customer experiences',
    titleWidth: 'maxWidth_300',
    description:
      'Custom software creates intuitive and smooth interactions. Self-service portals and mobile apps allow policyholders, brokers, and agents to manage policy data.',
  },
  {
    icon: 'spriteIcon_50',
    title: 'Data analytics and insights',
    titleWidth: 'maxWidth_300',
    description:
      'Insurance solutions provide valuable data that insurers can collect, analyze, and present to align operations, predict customer behavior, and reveal trends.',
  },
  {
    icon: 'spriteIcon_73',
    title: 'Risk assessment and underwriting',
    titleWidth: 'maxWidth_300',
    description:
      'Elaborate underwriting tools help insurers assess risks more accurately, define premium pricing and policy acceptance, and make informed decisions.',
  },
  {
    icon: 'spriteIcon_63',
    title: 'Regulatory compliance and security',
    titleWidth: 'maxWidth_300',
    description:
      'Tailored insurance software includes features that provide regulatory compliance and robust user data protection. Customers can entrust their data to insurers.',
  },
]

export default InsuranceBenefits
