import { ExpertiseSectionMock } from '~/types/mock'

const InsuranceWhyHire: ExpertiseSectionMock[] = [
  {
    title: 'Customized business consultation',
    description:
      'Our team helps you analyze opportunities and bottlenecks for your insurance solution. We create personalized development strategies for each project and drive you to success.',
  },
  {
    title: 'Clear communication',
    description:
      'We value your time and resources. That is why you can rely on us when you need to check the development progress or make adjustments.',
  },
  {
    title: 'Customer-centric approach',
    description:
      'Our skilled experts focus on delivering smooth user experiences, from product research to launch and support. As a result, a solution delights your prospects and customers.',
  },
  {
    title: 'Dedicated professionals',
    description:
      'As an insurance software development company, we take care of your project at each step using deep technical knowledge and the best practices in communication and collaboration.',
  },
  {
    title: 'Tailored software solutions',
    description:
      'By delivering scalable solutions in the insurance industry, we ensure you can adapt to your users’ feedback over time.',
  },
  {
    title: 'Continuous innovations and updates',
    description:
      'We help you create new insurance solutions and support existing ones to address market needs and sustain business progress.',
  },
  {
    title: 'Data-driven insights',
    description:
      'As our team monitors your solution’s performance, we can provide you with analytics and reports for business decision-making and developing growth strategies.',
  },
  {
    title: 'Results-oriented approach',
    description:
      'Having deep expertise in insurance software development, we bring tangible and measurable business outcomes.',
  },
]

export default InsuranceWhyHire
