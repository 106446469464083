import { ExpertiseSectionMock } from '~/types/mock'

const InsuranceServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_11',
    title: 'Custom insurance software development',
    description:
      'Our developers create robust insurance solutions within a secure infrastructure. We deliver insurance software that builds trust in your brand.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'Claims and policy management software',
    description:
      'Automate the processing of claims and policies and streamline policyholders’ onboarding. Provide policyholders with exceptional experiences and save operational costs.',
  },
  {
    icon: 'sprite3DIcon_18',
    title: 'Underwriting software',
    description:
      'Such solutions help you streamline underwriting processes for insurance agencies. Your customers will be able to analyze pricing and rates, calculate premiums, standardize quotes, and more.',
  },
  {
    icon: 'sprite3DIcon_21',
    title: 'Insurance pricing software and algorithms',
    description:
      'Assess customer information, coverage requirements, and risks. Streamline and expedite the pricing process for insurers to provide accurate price estimates.',
  },
  {
    icon: 'sprite3DIcon_10',
    title: 'Insurance document management',
    description:
      'Optimize processes and save costs for creating, organizing, managing, and retrieving insurance documents. Enhance data accuracy and ensure regulatory compliance via a single system.',
  },
  {
    icon: 'sprite3DIcon_8',
    title: 'Risk mitigation software',
    description:
      'We provide tools to identify, assess, monitor, and mitigate potential risks. The software helps you maintain stability, resilience, and security in insurance cases.',
  },
]

export default InsuranceServices
