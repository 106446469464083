import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const InsuranceProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Product discovery',
    descr: (
      <>
        Thorough research helps you take the right direction. We conduct
        <Link
          to="/services/product-discovery/"
          className="mx5"
          key="keyLinkProduct"
        >
          product discovery
        </Link>
        to evaluate your idea for the best results.
      </>
    ),
  },
  {
    num: '02',
    title: 'UI/UX design',
    descr: (
      <>
        With our
        <Link to="/services/ui-ux-design/" className="ml5" key="keyLinkDesign">
          UI/UX design services
        </Link>
        , you get a solution that delights your customers and aligns with your
        brand identity.
      </>
    ),
  },
  {
    num: '03',
    title: 'Development',
    descr:
      'We apply proven technologies and practices in insurance software development to create robust solutions and meet industry standards.',
  },
  {
    num: '04',
    title: 'Testing',
    descr: (
      <>
        Our
        <Link to="/services/quality-assurance/" className="mx5" key="keyLinkQA">
          QA services
        </Link>
        help check if the insurance software works correctly and aligns with
        your specifications for exceptional results.
      </>
    ),
  },
  {
    num: '05',
    title: 'Maintenance and support',
    descr:
      'Codica’s team of developers, testers, and DevOps engineers will keep your insurance software running smoothly and make updates.',
  },
]

export default InsuranceProcess
